.galleryElements {
    grid-gap: 0.3rem;
    margin: 1rem;
  }

.element {
  width: 100%;
  height: auto;
  border-radius: 0.7rem;
}

.galleryCheckbox:checked {
  transform: scale(1.1);
}

#lightbox_img {
  height: auto;
  width: auto;
  max-width: 80vw;
  max-height: 85vh;
  object-fit: cover;
  border-radius: 0.7rem;
}

#lightbox {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(24, 25, 27, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.7rem;
}

@media screen and (min-width: 981px) {
  #lightbox_img {
    margin-left: 5rem;
  }
}

@media screen and (max-width: 980px) {
  #lightbox_img {
    margin-bottom: 3.7rem;
  }
}

.lightbox_cross {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 3.5rem;
  height: 3.5rem;
  color: white;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}