.cardContainer {
    display: flex;
    width: 100%;
    flex-direction: row;
    padding: 20px;
    justify-content: space-around;
    margin-left: -2.5rem;
}
.cardLink {
    text-decoration: none;
    color: inherit;
    width: 40%;
}
.card {
    display: flex;
    flex-direction: row;
    background-color: var(--bg-secondary);
    border-radius: 10px;
    padding: 20px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.svg {
    fill: var(--accent-color);
    width: 2rem;
    height: 2rem;
}
.linkText {
    color: var(--text-secondary);
    font-size: 1.5rem;
    letter-spacing: 0.3ch;
    position: relative;
}

@media (max-width: 730px) {
    .cardContainer {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
    .cardLink {
        width: 90%;
    }

    .linkText {
        color: var(--text-secondary);
        font-size: 1.2rem;
        letter-spacing: 0.2ch;
        position: relative;
    }
}