@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,700&display=swap);
:root{
    font-size: 16px;
    font-family: 'Open Sans';
    --text-primary: #FFFFFF;
    --text-secondary: #9d9d9d;
    --accent-color: #cc9933;
    --lighter-accent-color: #d0ab2f;
    --bg-primary: #18191b;
    --bg-secondary: #1d2125;
    --bg-tertiary: #181818;
    --transition-speed: 500ms;
    scroll-behavior: smooth;
}

::-moz-selection { 
	color: inherit; 
}
::selection { 
	color: inherit; 
}

body{
    color: var(--text-primary);
    background-color: var(--bg-tertiary);
    margin: 0;
    padding: 0;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    -ms-overflow-style: none;
}

* {
    scrollbar-width: none;
}

body::-webkit-scrollbar {
    display: none;
}

hr {
    border-color: rgba(157, 157, 157, 0.283);
    border-width: 0.09rem;
    border-style: solid;
    margin-bottom: 1rem;
    border-radius: 4rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

@media only screen and (min-width: 981px) {
    body {
        margin-left: 5.5rem;
    }
}