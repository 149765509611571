.overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.5);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
}

.container {
    width: 40vw;
    height: 60vh;
    position: relative;
    z-index: 101;
    background: var(--bg-secondary);
    border-radius: 10px;
    overflow-y: auto;
}

.header {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    position: relative;
}

.header h1 {
    font-size: 1.5rem;
    font-weight: 500;
    color: var(--text-primary);
    margin-left: 1rem;
}

.header h2 {
    font-size: 1.5rem;
    font-weight: 500;
    color: var(--text-primary);
    margin-left: 1rem;
    position: absolute;
    right: 1rem;
}

.dottedLine {
    width: 100%;
    height: 2px;
    background: var(--text-secondary);
    opacity: 30%;
    position: absolute;
}

.events {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    
}

.event {
    border-radius: 10px;
    border-color: var(--accent-color);
    border-style: solid;
    margin-bottom: 0.5rem;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.event h3 {
    font-size: 2rem;
    font-weight: 800;
    color: var(--text-primary);
    margin-left: 1rem;
    margin-top: 0.5rem;
}

.uhrzeit {
    font-size: 0.9rem;
    font-weight: 500;
    color: var(--text-secondary);
    margin-left: 1rem;
    margin-top: -1.7rem;
}

.dauer {
    font-size: 0.9rem;
    font-weight: 500;
    color: var(--text-secondary);
    margin-left: 1rem;
    margin-top: -1rem;
}

.eventRight {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 1rem;
}

.button {
    width: 100%;
    height: 2rem;
    background: none;
    border: 2px solid var(--accent-color);
    border-radius: 10px;
    color: var(--accent-color);
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
}

.button:hover {
    background: var(--accent-color);
    color: var(--text-primary);
    transition: all 0.1s ease-in-out;
}

.button:disabled {
    background: var(--text-secondary);
    color: var(--text-primary);
    border: 2px solid var(--text-secondary);
    cursor: not-allowed;

}

@media screen and (min-width: 981px) {
   .container {
       margin-left: 4.5rem;
   }
}

@media screen and (max-width: 980px) {
    .container {
        margin-left: auto;
        margin-right: auto;
        width: 90vw;
    }

    .event {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .event h3 {
        margin-left: 0;
        font-size: 1.5rem;
        ;
    }

    .uhrzeit {
        margin-left: 0;
    }

    .dauer {
        margin-left: 0;
    }

    .eventRight {
        margin-right: 0;
    }

    .button {
        margin-bottom: 1rem;
    }
}