



/* not logged in */
.containerOut {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
}

.ueberschriftOut {
    margin-left: -4.3rem;
}

.linkOut {
    text-decoration: none;
    color: #cc9933;
    font-size: 1.5rem;
    margin: 1rem;
    margin-left: -4.3rem;
    padding: 1rem;
    border: 1px solid #cc9933;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
}

.linkOut:hover {
    background-color: #cc9933;
    color: #fff;
}