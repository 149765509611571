.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    width: 100%;
    height: 100%;
    background-color: #1d2125;
    border-radius: 0.7rem;
}

.title {
    font-size: 2.5rem;
    font-weight: 700;
    margin-top: 1rem;
    margin-bottom: 0rem;
    padding: 0;
    color: #FFFFFF;
}

.date {
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
    padding: 0;
    color: #9d9d9d;
}

.time {
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
    padding: 0;
    color: #9d9d9d;
}

.duration {
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
    padding: 0;
    color: #9d9d9d;
}

.info {
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
    padding: 0;
    color: #9d9d9d;
}

.link {
    color: #cc9933;
    margin-top: 6px;
    padding-bottom: 12px;
}

.seats {
    font-size: 1rem;
    font-weight: 400;
    margin: 0.5rem;
    padding: 0;
    color: #ffffff;
    margin-bottom: -0.5rem;
}

.button {
    font-size: 1rem;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    margin: 0;
    padding: 0;
    color: #FFFFFF;
    background-color: #cc9933;
    border-radius: 1rem;
    border: none;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    width: clamp(15rem, 50%, 50rem);
    height: 2rem;
}

.button:active {
    transform: scale(0.95);
}

