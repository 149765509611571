@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700&display=swap);

.infocard {
    background-color: var(--bg-secondary);
    border-radius: 0.7rem;
    margin: 1rem;
    border-color: #cc9933;
    border-width: 0.2rem;
    border-style: solid;
    display: flex;
    padding-left: 1rem;
    padding-right: 1rem;
    flex-direction: column;
    align-items: center;
}



@media only screen and (max-width: 981px) and (min-width: 368px) {
    .svg svg {
        fill: #cc9933;
        width: 2rem;
        display: flex;
        min-width: 3rem;
        margin-top: 0.5rem;
    } 

    .title {
        font-size: 19px;
        text-align: center;
    }

    .text {
        margin-left: 1rem;
        margin-right: 1rem ;
        text-align: center;
        font-size: 15px;
    }

    img {
        display: grid;
        width: 100%;
    }

}


@media only screen and (min-width: 981px) {
    .svg svg {
        fill: #cc9933;
        width: 4rem;
        display: flex;
        min-width: 3rem;
        margin-top: 0.5rem;
    }

    .text {
        margin-left: 1rem;
        margin-right: 1rem ;
        text-align: center;
    }

    .title {
        text-align: center;
    }
  
    img {
        display: grid;
        width: 100%;
    }
}

@media only screen and (max-width: 367px) {
    .svg svg {
        fill: #cc9933;
        width: 1.5rem;
        display: flex;
        min-width: 3rem;
        margin-top: 0.5rem;
    }

    .text {
        margin-left: 1rem;
        margin-right: 1rem ;
        text-align: center;
        font-size: 10px;
    }

    .title {
        font-size: 15px;
        text-align: center;
    }

    img {
        display: grid;
        width: 100%;
    }
}