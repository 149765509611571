.horizontal {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
}

@media screen and (max-width: 600px) {
  .horizontal {
    display: grid;
    grid-template-columns: 1fr;
  }
}

.events {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.entry {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;
}