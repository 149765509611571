.ccr_heading {
    margin: 0;
    padding: 0;
    margin-bottom: -1rem;
}

.kleinklein > * {
    font-size: 0.5rem;
    color: #ffffff;
    text-decoration: none;
    cursor: default;
}

.kleinklein:hover {
    text-decoration: underline;
}

.mail {
    color: var(--accent-color);
    text-decoration: none;
}

.mail:hover {
    text-decoration: underline;
}

.rechtshinweis_heading {
    margin-bottom: -0.7rem;
    color: var(--accent-color);
}

.datenschutz_svg {
    margin-right: -1rem;
}