@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,700&display=swap);

@media only screen and (max-width: 981px) and (min-width: 368px) {
    .links_links{
        font-size: 14px;
    }

    .impressum{
        font-size: 14px;
    }

    .footer{
        margin-bottom: 4.2rem;
    }
}

@media only screen and (min-width: 981px) {
    .links_links{
        font-size: 21px;
    }

    .impressum{
        font-size: 21px;
    }

    .footer{
        margin-bottom: 0.5rem;
    }

}

@media only screen and (max-width: 367px) {
    .links_links{
        font-size: 13px;
    }

    .impressum{
        font-size: 13px;
    }

    .footer{
        margin-bottom: 3.5rem;
    }
}

.links{
    display: flex;
    justify-content: center;
    padding-bottom: 1rem;
    flex-direction: row;
}

.links_links{
    padding-left: 1vh;
    padding-right: 1vh;
    padding-top: 0.2rem;
    text-decoration: none;
    color: var(--accent-color);
}

.impressum{
    display: flex;
    justify-content: center;
    text-decoration: none;
    color: var(--accent-color);
}

.links_links:hover{
    text-decoration: underline;
}

.impressum:hover{
    text-decoration: underline;
}

.footer{
    background-color: var(--bg-secondary);
    border-radius: 0.7rem;
    padding-bottom: 0.7rem; 
}

.sm_nav{
    display: flex;
    justify-content: center;
    color: var(--accent-color);
    list-style: none;
}

.sm_link svg{
    height: 5rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1rem;
    padding-bottom: 0rem;
    fill: var(--accent-color);
}

.sm_link{
    color: var(--bg-primary);
}

.sm_text{
    display: none
}

.sm_link:hover svg{
    transition: var(--transition-speed);
    transform: translateY(-2px) scale(1.11);
}

.socialmedia {
    margin-left: -3.2rem;
}

.copy {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    margin-top: 0.8rem;
    font-size: 13px;
    color: var(--lighter-accent-color);
    filter: grayscale(100%) opacity(0.7);
}