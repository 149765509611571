@import url(https://fonts.googleapis.com/css2?family=Cormorant+SC&display=swap);

.header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 -0.5rem -1.5rem -0.5rem;
    padding: 0;
    border: 0;
}

.header h1 {
    position: absolute;
    font-family: 'Cormorant SC';
    display: flex;
    text-shadow: 0 0 1rem black;
}

.header img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: 0;
    padding: 0;
}

@keyframes scrollAnimation {
    0%, 100% {
      transform: translateY(0); /* Initial and final position */
    }
    50% {
      transform: translateY(20px); /* Middle position, arrow moves down */
    }
}

.horses {
    display: grid;
    grid-auto-flow: column;
    overflow-y: hidden;
    overscroll-behavior-inline: contain;
    scroll-snap-type: inline mandatory;
    margin-bottom: -0.5rem;
    gap: 1rem;
}

.horses::-webkit-scrollbar {
    height: 0.5rem;
}

.horses::-webkit-scrollbar-track {
    background-color: #1d2125a6;
    border-radius: 100vw;
}

.horses::-webkit-scrollbar-thumb {
    background-color: #cc9933;
    border-radius: 100vw;
}

@supports (scrollbar-color: #cc9933 #1d2125a6) {
    .horses {
        scrollbar-color: #cc9933 #1d2125a6;
        scrollbar-width: thin;
    }
}

@media only screen and (min-width: 901px ) {
    .training_kurse {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(29rem, 1fr));
        margin-top: -1rem;
    }


}


@media only screen and (min-width: 571px ) and (max-width: 900px) {
    .training_kurse {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(29rem, 1fr));
        margin-top: -1rem;
    }
}

@media only screen and (min-width: 1776px) {
    .header {
        height: 100vh;
    }

    .header h1 {
        font-size: 12rem;
        margin-top: 6rem;
    }

    .scrollDownSVG {
        display: flex;
        width: 3rem;
        height: 3rem;
        position: absolute;
        fill: white;
        bottom: 4rem;
        animation: scrollAnimation 2s infinite;
        cursor: pointer;
    }
}

@media only screen and (max-width: 1776px) and (min-width: 571px) {
    .header {
        height: 80vh;
    }

    .header h1 {
        font-size: 6rem;
    }

    .scrollDownSVG {
        display: none;
    }

}

@media only screen and (max-width: 571px) {
    .header {
        height: 60vh;
    }
    .header h1 {
        font-size: 4rem;
    }

    .scrollDownSVG {
        display: none;
    }
}



.wrc_turnier {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(9rem, 1fr));
    margin: 0;
    margin-bottom: -1rem;
    overflow: 0;
    padding: 0;
}

.unter_unterschrift {
    padding: 0;
    margin: 0;
}

.train_heading {
    text-align: center;
    display: flex;
    justify-content: center;
    margin-top: -1rem;
}

.horses > * {
   scroll-snap-align: center;
}