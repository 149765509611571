.eventContainer {
    padding: 1rem;
    padding-top: 0rem;
    border-radius: 0.7rem;
    max-width: 800px;
    color: var(--text-primary);
    background-color: var(--bg-secondary); /* Add background color */
    margin: 0rem auto;
}

.eventTitle {
    font-size: 2rem;
    color: var(--accent-color);
    margin-bottom: 0.5rem;
}

.eventInfo {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    color: #ffffff;
}

.participantsList {
    list-style: none;
    padding: 0;
}

.participant {
    background-color: var(--bg-tertiary);
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    border-radius: 0.5rem;
}

@media screen and (max-width: 768px) {
    .eventContainer {
        padding: 0.5rem;
    }

    .eventTitle {
        font-size: 1.5rem;
    }

    .eventInfo {
        font-size: 0.9rem;
    }
}