.cookiebox {
    display: flex;
    background-color: var(--bg-secondary);
    color: #ffffff;
    border-radius: 1rem;
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    width: 20rem;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    align-items: center;
}

.cookieLink {
    color: var(--accent-color);
    text-decoration: underline;
    margin-top: -0.5rem
}

.cookieBtn {
    background-color: var(--accent-color);
    color: #ffffff;
    border: none;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    margin: 0.5rem;
    cursor: pointer;
    margin-bottom: 1rem;;
}

.cookieBtnDecline {
    background-color: var(--text-secondary);
    color: #ffffff;
    border: none;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    margin: 0.5rem;
    cursor: pointer;
    margin-bottom: 1rem;;
}

@media only screen and (max-width: 981px) {
    .cookiebox {
        bottom: 4rem;
        width: 96vw;
        justify-content: center;
        position: fixed;
        left: 2vw;
    }

    .cookiebox svg {
        width: 2rem;
        margin-bottom: -0.4rem;
        margin-right: 0.2rem;
    }

    .openBtnContainer {
        bottom: 4.2rem !important;
        right: 0.5rem !important;
    }
}

.cookiebox h2 {
    margin: 0;
    padding: 0;
}

.cookiebox svg {
    fill: #cc9933;
    margin-top: 1rem;
}

.openBtnContainer {
    position: fixed;
    display: flex;
    bottom: 1rem;
    right: 1rem;
    width: 4rem;
    height: 4rem;
    background-color: var(--bg-secondary);
    border-radius: 2rem;
    justify-content: center;
    align-items: center;
}

.openBtnContainer:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.openBtnContainer svg {
    fill: var(--accent-color);
    width: 3rem;
}

.hide {
    display: none !important;
}
