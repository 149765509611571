.input {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #cc9933;
    background-color: #1d2125;
    color: #fff;
    padding: 0 10px;
    font-size: 16px;
    margin-bottom: 10px;
    box-sizing: border-box;
    outline: none;
    transition: all 0.3s ease;
}

.input::placeholder {
    color: #9d9d9d;
}

.input:focus {
    border: 2px solid #cc9933;
}



.submitButton {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: none;
    background-color: #cc9933;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    outline: none;
    transition: all 0.3s ease;
}

.submitButton:hover {
    background-color: #b38600;
}

.submitButton:active {
    background-color: #b38600;
}