@import url(https://fonts.googleapis.com/css2?family=Cormorant+SC&display=swap);

.boxes {
    display: grid;
    margin-top: -1rem;
}

.header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 -0.5rem -1.5rem -0.5rem;
    padding: 0;
    border: 0;
}

.header h1 {
    position: absolute;
    font-family: 'Cormorant SC';
    display: flex;
    text-shadow: 0 0 1rem black;
}

.header img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: 0;
    padding: 0;
}

@keyframes scrollAnimation {
    0%, 100% {
      transform: translateY(0); /* Initial and final position */
    }
    50% {
      transform: translateY(20px); /* Middle position, arrow moves down */
    }
  }
  

@media only screen and (min-width: 1776px) {
    .boxes {
        grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    }

    .header {
        height: 100vh;
    }

    .header h1 {
        font-size: 12rem;
        margin-top: 6rem;
    }

    .scrollDownSVG {
        display: flex;
        width: 3rem;
        height: 3rem;
        position: absolute;
        fill: white;
        bottom: 4rem;
        animation: scrollAnimation 2s infinite;
        cursor: pointer;
    }
}

@media only screen and (max-width: 1776px) and (min-width: 571px) {
    .boxes {
        grid-template-columns: repeat(auto-fit, minmax(35rem, 1fr));
    }

    .header {
        height: 80vh;
    }

    .header h1 {
        font-size: 6rem;
    }

    .scrollDownSVG {
        display: none;
    }

}

@media only screen and (max-width: 571px) {
    .boxes {
        grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    }

    .header {
        height: 60vh;
    }
    .header h1 {
        font-size: 4rem;
    }

    .scrollDownSVG {
        display: none;
    }
}

