@import url(https://fonts.googleapis.com/css2?family=Cormorant+SC&display=swap);

iframe {
    border-radius: 0.7rem;
    margin-top: 1rem;
    border-width: 0;
    border-style: solid;
    width: 100vw;
    height: 45vw;
}

@media screen and (max-width: 980px) {
    iframe {
        height: 60vw;
        border: none !important;
    }
}

.map {
    display: flex;
    justify-content: center;
    margin-top: -1rem;
}

.blurredMap {
    background-image: url(../../pictures/site/mapBlur.webp);
    background-size: cover;
    border-radius: 0.7rem;
    margin-top: 1rem;
    border-width: 0;
    border-style: solid;
    width: 100vw;
    height: 45vw;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.blurredMap>* {
    font-size: clamp(16px, 3vw, 2rem);
}

.welcome_info_wrapper {
    display: flex;
    justify-content: center;
}

@media only screen and (min-width: 981px) {
    .welcome_info div {
        width: 80% !important;
    }
}

.linkings {
    margin-right: 0.3rem;
}

.linkings:hover {
    transform: scale(1.01);
    transition: 0.1s;
}

.linkings img {
    border-color: transparent;
    border-width: 0.2rem;
    border-radius: 0.9rem;
    border-style: solid;
}

.linkings:hover img {
    border-color: #cc9933;
}

.linker {
    text-decoration: none;

}

.welcome_info {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100dvh;
    text-align: center;
}

.welcome_info_firstPart {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    height: 3rem;
}

.welcome_info_secondPart {
    font-family: 'Cormorant SC';
    font-size: 1.5em;
    margin: 0;
}

.welcome_info_secondPart h1 {
    margin: 0;
}

.welcome_info_thirdPart {
    font-family: 'Cormorant SC';
    font-size: 1.4em;
    color: #cc9933;
}

.welcome_info h3 {
    color: #cc9933;
    height: auto;
    width: auto;
    font-size: 1em;
}

.welcome_info svg {
    fill: #cc9933;
    height: 3rem;
}

@media screen and (max-width: 980px) {
    .welcome_info_secondPart {
        font-family: 'Cormorant SC';
        font-size: 1.3em;
    }

    .welcome_info_thirdPart {
        font-family: 'Cormorant SC';
        font-size: 1.2em;
        color: #cc9933;
    }

}