@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,700&display=swap);

.navbar{
    position: fixed;
    background-color: var(--bg-secondary);
    transition: width 175ms ease;
    z-index: 999;
}

.navbar_nav {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.nav_item {
    width: 100%;
}

.nav_link{
    display: flex;
    align-items: center;
    color: var(--text-primary);
    text-decoration: none;

    transition: var(--transition-speed);
}

.svg{
    fill: var(--accent-color);
}

.about .svg {
    height: 2.2rem !important;
}

.link_text{
    display: none;
    margin-left: 1rem;
}

.nav_link:hover{
    color: var(--lighter-accent-color);
    filter: grayscale(0%) opacity(1);
}

.logo {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 1rem;
    text-align: center;
    color: var(--text-secondary);
    background: var(--bg-primary);
    font-size: 1.5rem;
    letter-spacing: 0.3ch;
    width: 100%;
}
  
.logo svg {
   transition: var(--transition-speed);
}
  
.logo-text{
   display: inline;
   position: absolute;
   left: -999px;
   transition: var(--transition-speed);
}

@media only screen and (max-width: 980px) and (min-width: 368px) {
    .navbar{
        bottom: 0;
        width: 100vw;
        height: 3.7rem;
        margin-left: -0.5rem;
    }

    .logo{
        display: none;
    }

    .navbar_nav{
        flex-direction: row;
        justify-content: center;
    }

    .nav_link {
        display: flex;
        justify-content: center;
        height: 100%;
        color: var(--accent-color);
        flex-direction: column;
    }

    main{
        margin: 0;
        margin-bottom: 4rem;
    }

    .nav_link svg {
        height: 1.4rem;
        min-width: 1rem;
        margin: 0 1.5rem;
    }

    .about .svg {
        height: 1.4rem !important;
        min-width: 1rem !important;
        margin: 0 1.5rem !important;
    }

    .link_text {
        display: flex;
        font-size: 11px;
        padding: 0;
        margin: 0;
    }

    #ab_svg{
        width: 1.5rem !important; 
    }

    .about {
        margin-bottom: -0.17rem;
        
    }
}

@media only screen and (max-width: 390px) {
    .navbar{
        bottom: 0;
        width: 100vw;
        height: 3rem;
        margin-left: -0.5rem;
       
    }

    .logo{
        display: none;
    }

    .navbar_nav{
        flex-direction: row;
    }

    .nav_link {
        display: flex;
        justify-content: center;
        height: 100%;
        color: var(--accent-color);
        flex-direction: column;
        margin-left: -0.3rem;
    }

    main{
        margin: 0;
        margin-bottom: 1.9rem;
    }

    .nav_link svg {
        width: 1rem;
        min-width: 0.4rem;
    }

    .about .svg {
        height: 1rem !important;
        min-width: 0.4rem !important;
    }

    #ab_svg{
        width: 1.3rem;
    }

    .link_text {
        display: flex;
        font-size: 9px;
        padding: 0;
        margin: 0;
    }
}

@media only screen and (min-width: 981px){

   .navbar{
    top: 0;
    width: 5rem;
    height: 100vh;
    margin-left: -5.5rem;
   }

   .nav_link{
    height: 5rem;
    filter: grayscale(100%) opacity(0.7);
   }

   .navbar:hover{
    width: 14rem;
   }
   .navbar:hover .link_text{
    display: inline;
    transition: opacity var(--transition-speed);
   }

   .nav_item:last-child {
    margin-top: auto;
    }

    .nav_link svg {
        width: 2rem;
        min-width: 2rem;
        margin: 0 1.5rem;
    }
}