@import url(https://fonts.googleapis.com/css2?family=Cormorant+SC&display=swap);

.header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 -0.5rem -1.5rem -0.5rem;
    padding: 0;
    border: 0;
}

.header h1 {
    position: absolute;
    font-family: 'Cormorant SC';
    display: flex;
    text-shadow: 0 0 1rem black;
}

.header img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: 0;
    padding: 0;
}

.scrollDownSVG {
    display: flex;
    width: 3rem;
    height: 3rem;
    position: absolute;
    fill: white;
    bottom: 4rem;
    animation: scrollAnimation 2s infinite;
    cursor: pointer;
}

.phone {
    display: grid;
    width: 50%;
    grid-template-areas: 
        "svg topRight"
        "svg bottomRight";
    grid-template-columns: auto 1fr;
    align-items: center;
    margin: 0;
    padding: 0;
    border: 3px solid var(--accent-color);
    border-radius: 1rem;
    background-color: var(--bg-secondary);
}

.phoneSvg {
    grid-area: svg;
    fill: #cc9933;
    height: 5rem;
    margin-left: 1rem;
}

.phoneParagraph {
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: 1.2rem;
    margin: 0;
    padding: 0;
    border: 0;
    margin-left: 1rem;
}

.phoneAnchor {
    color: var(--accent-color);
    text-decoration: none;
}

.phoneAnchor:hover {
    color: var(--accent-color);
    text-decoration: underline;
}

#pAnna {
    grid-area: topRight;
}

#pOlli {
    grid-area: bottomRight;
}

.mail {
    display: grid;
    width: 50%;
    grid-template-areas: 
        "svg mail"
        "svg mail";
    grid-template-columns: auto 1fr;
    align-items: center;
    margin: 0;
    padding: 0;
    border: 3px solid var(--accent-color);
    border-radius: 1rem;
    background-color: var(--bg-secondary);

}

.mailSvg {
    grid-area: svg;
    fill: #cc9933;
    height: 5rem;
    margin-left: 1rem;
}

#pMail {
    grid-area: mail;
}

.mailParagraph {
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: 1.2rem;
    margin: 0;
    padding: 0;
    border: 0;
    margin-left: 1rem;
}

.mailAnchor {
    color: var(--accent-color);
    text-decoration: none;
}

.mailAnchor:hover {
    color: var(--accent-color);
    text-decoration: underline;
}

@media only screen and (max-width: 724px) {
    .content {
        flex-direction: column !important;
    }
    .phone {
        width: 100%;
        height: 7rem;
    }

    .mail {
        width: 100%;
        height: 7rem;
    }
}

@keyframes scrollAnimation {
    0%, 100% {
      transform: translateY(0); /* Initial and final position */
    }
    50% {
      transform: translateY(20px); /* Middle position, arrow moves down */
    }
}

@media only screen and (min-width: 1776px) {
    .header {
        height: 100vh;
    }

    .header h1 {
        font-size: 12rem;
        margin-top: 6rem;
    }

    .scrollDownSVG {
        display: flex;
        width: 3rem;
        height: 3rem;
        position: absolute;
        fill: white;
        bottom: 4rem;
        animation: scrollAnimation 2s infinite;
        cursor: pointer;
    }
}

@media only screen and (max-width: 1776px) and (min-width: 571px) {
    .header {
        height: 80vh;
    }

    .header h1 {
        font-size: 6rem;
    }

    .scrollDownSVG {
        display: none;
    }

}

@media only screen and (max-width: 571px) {
    .header {
        height: 60vh;
    }
    .header h1 {
        font-size: 4rem;
    }

    .scrollDownSVG {
        display: none;
    }
}

.content {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}