.notfoundContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    padding: 20px;
    background-color: var(--bg-secondary); /* Using variable from App.css */
    color: var(--text-primary); /* Text color consistent with the rest of the site */
    font-family: 'Roboto', sans-serif; /* Font family from App.css */
}

.notfoundContent {
    max-width: 600px;
}

.notfoundTitle {
    font-size: 4rem; /* Larger size for emphasis */
    color: var(--accent-color); /* Accent color for the title */
    margin-bottom: 20px;
}

.notfoundMessage {
    font-size: 1.5rem; /* Slightly larger for readability */
    margin-bottom: 30px;
    color: var(--text-secondary); /* Secondary text color for contrast */
}

.notfoundHomeButton {
    display: inline-block;
    background-color: var(--accent-color); /* Accent color for button */
    color: var(--text-primary);
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color var(--transition-speed); /* Smooth transition for hover effect */
    font-weight: 700; /* Bold text for the button */
}

.notfoundHomeButton:hover {
    background-color: var(--lighter-accent-color); /* Lighter accent color on hover */
}