.calender {
    margin-bottom: 20rem;
}


.monthPicker {
    display: flex;
    justify-content: center;
    align-items: center;
}

.monthPickerButton {
    border: none;
    color: var(--accent-color);
    background-color: transparent;
    font-size: 1.5rem;
    cursor: pointer;
    margin: 5px 1px 1px 1px;
}

.dayNames {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    align-items: center;
}

.dayName {
    color: var(--text-secondary);
    font-weight: 400;
    text-align: center;
}

.weeks {
    display: grid;
    grid-template-rows: (5, 1fr);
    gap: 10px;
    height: 50vh;
    margin-bottom: 4rem;
}

.week {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    align-items: center;;
    gap: 10px;
    height: 12vh;
}

.day {
    position: relative;
    border: 1px solid var(--text-secondary);
    border-radius: 5px;
    height: 12vh;
}

.dayNumber {
    position: absolute;
    top: 3px;
    left: 4px;
    font-size: 1rem;
    font-weight: 400;
}

.dayEvent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 25px;
    text-align: center;
    color: var(--accent-color);
    font-weight: 700;
}

.event .isEvent{
    color: var(--accent-color);
    font-weight: 700;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3vw;
}

.notEvent .isEvent {
    color: transparent;
}

.day:hover {
    background-color: var(--bg-secondary);
    cursor: pointer;
}